import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useRecoilValue } from 'recoil';
import { colorthemeAtom } from '../../../../../../recoil/atoms/atoms';
import { useState } from 'react';

interface QueryProps {
  query: string;
  onGenerate: (newQuery: string) => Promise<void>;
  isLoading?: boolean;
}

export const Query = ({ query, onGenerate }: QueryProps) => {
  const colorTheme = useRecoilValue(colorthemeAtom);
  const [copied, setCopied] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editableQuery, setEditableQuery] = useState<string>(query);
  const [isLoadingModify, setIsLoadingModify] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(editableQuery).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleGenerate = () => {
    setIsEditing(true);
  };

  const handleSubmit = () => {
    if (onGenerate && editableQuery.trim()) {
      setIsLoadingModify(true);
      onGenerate(editableQuery).finally(() => {
        setIsLoadingModify(false);
        setIsEditing(false);
      });
    }
  };

  return (
    <div className='w-full overflow-x-hidden'>
      <div className='flex items-center gap-2'>
        <button
          onClick={() => setIsCollapsed(!isCollapsed)}
          className='text-sm hover:bg-gray-100 dark:hover:bg-gray-800 p-1 rounded'
        >
          {isCollapsed ? '▶' : '▲'}
        </button>
        <span>Query Generated</span>
      </div>
      {!isCollapsed && (
        <div className='w-auto h-auto mt-4 border border-outline_unselected rounded-lg flex flex-row'>
          {/* Query Display Section */}
          {!isEditing && (
            <div className='flex-1'>
              <SyntaxHighlighter language="sql"
                style={colorTheme === 'dark' ? vs2015 : vs}
                wrapLongLines={true}
                customStyle={{ padding: '0.75em', background: 'none', fontSize: '1rem', lineHeight: '1.5rem', letterSpacing: '0.025em' }}>
                {query}
              </SyntaxHighlighter>
            </div>
          )}
          {/* Editable Text Area */}
          {isEditing && (
            <div className="flex flex-1 flex-col">
              <textarea
                value={editableQuery}
                onChange={(e) => setEditableQuery(e.target.value)}
                disabled={isLoadingModify}
                className="w-full h-32 p-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none dark:bg-gray-800 dark:text-white bg-white disabled:opacity-50"
                style={{ minHeight: '150px' }}
              />
              <div className="flex justify-end gap-3 p-3">
                <button
                  onClick={handleSubmit}
                  disabled={isLoadingModify || !editableQuery.trim()}
                  className="text-black-600 dark:text-white bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 font-bold px-4 py-2 rounded shadow-lg transition-all flex items-center gap-2 disabled:opacity-50"
                >
                  {isLoadingModify ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Processing...
                    </>
                  ) : (
                    'Submit Query'
                  )}
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  disabled={isLoadingModify}
                  className="text-black-600 dark:text-white bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 font-bold px-4 py-2 rounded shadow-lg transition-all flex items-center gap-2 disabled:opacity-50"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {/* Copy Status */}
          {!isEditing && (
            <div>
              {copied ? (
                <span className="min-w-6 h-6 text-xs my-3 mx-1">
                  Copied
                </span>
              ) : (
                <div
                  className='min-w-6 h-6 m-3 bg-lightcopy dark:bg-copy bg-cover cursor-pointer justify-self-end group relative hover:bg-lightcopy-hover dark:hover:bg-copy-hover'
                  onClick={handleCopy}>
                  <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-sm opacity-0 group-hover:opacity-100 transition-opacity">
                    Copy
                  </span>
                </div>
              )}
              <button
                onClick={handleGenerate}
                className="flex items-center gap-2 text-gray-600 hover:text-gray-300 font-medium px-3 py-1 rounded transition-all group relative"
              >
                <span className="absolute -top-8 left-1/2 -translate-x-1/2 bg-gray-800 text-white px-2 py-1 rounded text-sm opacity-0 group-hover:opacity-100 transition-opacity">
                Modify
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
