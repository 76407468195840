import { useCallback, useEffect, useRef } from 'react';
import { DialogDto } from '../../../../../apis/conversations/interfaces';
import Avatar from './components/avatar';
import { Response } from './components/response';
import { useRecoilValue } from 'recoil';
import { asyncDialogAtom } from '../../../../../recoil/atoms/atoms';
import RateButton from './components/rate_button';
import { createWidget } from '../../../../../apis/dashboard/api';

interface ChatHistoryProps {
  dialogs: DialogDto[] | undefined;
  sentInput: string;
  isLoading: boolean;
  profileLink?: string;
  username: string;
  regenerateQueryAsync: (updatedQuery: string, dialogId: string) => void;
}

export default function ChatHistory({ dialogs, isLoading, sentInput, profileLink, username, regenerateQueryAsync }: ChatHistoryProps) {
  const asyncDialog = useRecoilValue<DialogDto>(asyncDialogAtom);

  const dialogEndRef = useRef(null);

  const scrollToBottom = () => {
    dialogEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    if (isLoading) {
      scrollToBottom();
    }
  }, [isLoading]);

  useEffect(() => {
    if (dialogs?.length !== 0) {
      scrollToBottom();
    }
  });
  const handleCreateWidget = async (config: string, dialogId: string) => {
    try {
      await createWidget({
        name: 'Visualization Widget',
        // TODO: Implement dashboardId on Dashboard  part 2
        dashboardId: '35463b33-5586-4d09-8469-45cd0b04ef66',
        dialogId: dialogId,
      });
    } catch (error) {
      console.error('Failed to create widget:', error);
    }
  };

  const handleRegenerateQuery = async (query: string, dialogId: string): Promise<void> => {
    if (query.trim()) {
      return regenerateQueryAsync(query, dialogId);
    } else {
      console.log('Query is empty');
      return Promise.resolve();
    }
  };

  // TODO: Implement event listener to change dialog request
  const renderDialogs = useCallback((dialogs: DialogDto[], isAsyncDialog: boolean) => {
    return dialogs?.map((dialog) => {
      return (
        <div key={'dialog' + dialog?.dialogId}>
          <div className="flex w-full min-h-20 h-auto border-b-2 border-b-border_selected">
            <Avatar isLegion={false} profileLink={profileLink} username={username} isLoading={isAsyncDialog} />
            <div className='flex-1 max-w-fit my-4 p-3 h-auto text-text_unselected text-base text-balance bg-layer2 rounded-md'>
              <span>
                {isLoading && isAsyncDialog ? sentInput : dialog?.userInput}
              </span>
            </div>
            {/* <div className='pt-7 pl-3'>
              <div className='w-6 h-6 bg-lightedit dark:bg-edit cursor-pointer' />
            </div> */}
          </div>
          <div className="flex flex-col w-full">
            {dialog?.responses?.map((response, index) => (
              response.ai_chart ? (
                <div key={index} className="relative">
                  <div className="absolute top-2 right-2 z-10">
                    <button
                      onClick={() => handleCreateWidget(response.ai_chart!, dialog.dialogId)}
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"                    >
                      <svg
                        className="w-4 h-4 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 4v16m8-8H4"
                        />
                      </svg>
                      Add to Dashboard
                    </button>
                  </div>
                </div>
              ) : null
            ))}
            <Response
              responses={dialog?.responses}
              isAsyncDialog={isAsyncDialog}
              onGenerate={(updatedQuery) => handleRegenerateQuery(updatedQuery, dialog.dialogId)}
            />
          </div>
          {
            !isAsyncDialog && <RateButton dialogId={dialog.dialogId} dialogRating={dialog.dialogRating} />
          }
        </div>
      );
    });
  }, [profileLink, username, sentInput]);

  return (
    <div className="h-full flex flex-col px-16 overflow-y-auto">
      {renderDialogs(dialogs, false)}
      {/* When loading */}
      {isLoading && sentInput && renderDialogs([asyncDialog], true)}
      <div ref={dialogEndRef} />
    </div>
  );
}
