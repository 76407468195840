import { instance } from '../base/instance';
import {
  ConversationDto,
  CreateConversationDto,
  CreateDialogDto,
  DialogDto,
  VoidResponseDto,
} from './interfaces';

export const startNewConversation = async (
  conversation: CreateConversationDto
): Promise<string> => {
  const res = await instance.post('/conversations', conversation);
  return res.data.result.conversationId;
};

export const getAllConversations = async (): Promise<ConversationDto[]> => {
  const res = await instance.get('/conversations');
  return res.data;
};

export const deleteConversation = async (id: string): Promise<void> => {
  await instance.delete('/conversations/' + id);
};

export const getConversation = async (id: string): Promise<ConversationDto> => {
  const res = await instance.get('/conversations/' + id + '/');
  return res.data;
};

export const sendDialog = async (
  id: string,
  dialog: CreateDialogDto
): Promise<DialogDto> => {
  const res = await instance.put('/conversations/' + id, dialog);
  return res.data;
};

export const getAsyncDialog = async (
  conv_id: string,
  dialog_id: string,
): Promise<DialogDto> => {
  const res = await instance.get('/conversations/' + conv_id + '/' + dialog_id);
  return res.data;
};

export const rateDialog = async (
  conv_id: string,
  dialog_id: string,
  rating: string
): Promise<VoidResponseDto> => {
  const res = await instance.put(
    `/conversations/${conv_id}/${dialog_id}/rating`,
    { rating: rating }
  );
  return res.data;
};

export const regenerateQuery = async (
  convId: string,
  dialogId: string,
  dialog: CreateDialogDto
): Promise<DialogDto> => {
  const res = await instance.put(`/conversations/${convId}/${dialogId}/query`, dialog);
  return res.data;
};

export const stopDialog = async (
  convId: string,
  dialogId: string
): Promise<VoidResponseDto> => {
  console.log(`Sending stop request for dialog ID: ${dialogId}`); // Debug log
  const res = await instance.put(`/conversations/${convId}/${dialogId}/stop`);
  return res.data;
};
