import { useEffect, useState } from 'react';
import { registerAtom } from '../../../recoil/atoms/atoms';
import { useSetRecoilState } from 'recoil';
import { getEmailVerificationCode, registerUser } from '../../../apis/auth/apis';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [understand, setUnderstand] = useState(false);
  const [inviteId, setInviteId] = useState('');
  const [showUsernameHint, setShowUsernameHint] = useState(false);
  const [showPasswordHint, setShowPasswordHint] = useState(false);
  const [checkPasswordLength, setCheckPasswordLength] = useState(false);
  const [checkPasswordUpperLetter, setCheckPasswordUpperLetter] = useState(false);
  const [checkPasswordLowerLetter, setCheckPasswordLowerLetter] = useState(false);
  const [checkPasswordNumber, setCheckPasswordNumber] = useState(false);
  const [checkPasswordSpecialLetter, setCheckPasswordSpecialLetter] = useState(false);

  const setRegisterAtom = useSetRecoilState(registerAtom);
  const nagivate = useNavigate();
  const onEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const onPasswordInput = (event) => {
    setPassword(event.target.value);
  };

  const onUsernameInput = (event) => {
    setUsername(event.target.value);
  };

  const onInviteIdInput = (event) => {
    setInviteId(event.target.value);
  };

  const onEyeClick = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const onUnderstandClick = () => {
    setUnderstand((understand) => !understand);
  };

  const onSignUpClick = async () => {
    if (!email) {
      Swal.fire('Error', "Email can't be empty!", 'error');
      return;
    }

    if (!understand) {
      Swal.fire(
        'Error',
        'Terms of Use and Privacy Policy must be read, understand and agree',
        'error'
      );
      return;
    }

    try {
      await registerUser({ email, username, password, type: 'type', emailCode: 'inviteId', validationOnly: true });
    } catch (error) {
      Swal.fire(
        'Error',
        error?.data ? error.data.replaceAll('\n', '<br />') : 'illegal input given',
        'error'
      );
      return;
    }

    setRegisterAtom({
      username: username,
      password: password,
      type: 'FIRST',
      email: email,
      emailCode: '',
      invite_id: inviteId,
    });



    getEmailVerificationCode(email).then((res) => {
      if (res) {
        nagivate('/verify-email');
      }
    });
  };

  const onSignInClick = () => {
    nagivate('/sign-in');
  };

  useEffect(() => {
    setCheckPasswordLength(password.length >= 8);
    setCheckPasswordUpperLetter(password !== password.toLowerCase());
    setCheckPasswordLowerLetter(password !== password.toUpperCase());
    setCheckPasswordNumber(/\d/.test(password));
    setCheckPasswordSpecialLetter(/[!-/:-@[-`{-~]/.test(password));
  }, [password]);

  return (
    <div className="grid justify-items-center justify-self-center self-center w-1/3 h-auto bg-layer2 max-w-md">
      <div className="text-xl font-bold text-text_selected w-10/12 my-8 text-center">
        <span>Get Started with Legion AI</span>
        <input
          className="mt-8 w-full h-12 rounded border-solid border-active_hover focus:border-active border bg-transparent pl-6 text-sm font-normal outline-none"
          placeholder="Email Address"
          type="text"
          value={email}
          onChange={onEmailInput}
        />
        <input
          className="mt-2 w-full h-12 rounded border-solid border-active_hover focus:border-active border bg-transparent pl-6 text-sm font-normal outline-none"
          placeholder="Username"
          type="text"
          value={username}
          onChange={onUsernameInput}
          onFocusCapture={() => setShowUsernameHint(true)}
        />
        {showUsernameHint && <div className='text-left w-full text-sm font-normal pt-1 pl -1'>
          <span>Only be alphabets, numbers, or symbol with length 5 - 15</span>
        </div>}
        <div className="flex justify-between items-center mt-2 w-full h-12 rounded border-solid border-active_hover focus-within:border-active border bg-transparent">
          <div className="grow h-full pl-6 pr-6">
            <input
              className="h-full w-full text-sm font-normal bg-transparent outline-none"
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={onPasswordInput}
              onFocusCapture={() => setShowPasswordHint(true)}
            />
          </div>
          {showPassword ? (
            <div
              className="mr-6 w-6 h-6 bg-eye bg-cover cursor-pointer"
              onClick={onEyeClick}
            />
          ) : (
            <div
              className="mr-6 w-6 h-6 bg-eyeslash bg-cover cursor-pointer"
              onClick={onEyeClick}
            />
          )}
        </div>
        {showPasswordHint && (
          <div className='text-left w-full text-sm font-normal pt-1 pl -1 text-text_unselected_secondary'>
            <span className={`${checkPasswordLength && 'text-text_selected'}`}>At least 8 characters</span>
            <span> | </span>
            <span className={`${checkPasswordUpperLetter && 'text-text_selected'}`}>One uppercase</span>
            <span> | </span>
            <span className={`${checkPasswordLowerLetter && 'text-text_selected'}`}>One lowercase</span>
            <span> | </span>
            <span className={`${checkPasswordNumber && 'text-text_selected'}`}>One number</span>
            <span> | </span>
            <span className={`${checkPasswordSpecialLetter && 'text-text_selected'}`}>One special character</span>
          </div>
        )}
        <input
          className="mt-2 w-full h-12 rounded border-solid border-active_hover focus:border-active border bg-transparent pl-6 text-sm font-normal outline-none"
          placeholder="Invite Id (Optional)"
          type="text"
          value={inviteId}
          onChange={onInviteIdInput}
        />
        <div className="flex items-center mt-3 w-full h-7">
          {understand ? (
            <div
              className="h-4 w-4 bg-checked bg-cover bg-center rounded cursor-pointer"
              onClick={onUnderstandClick}
            />
          ) : (
            <div
              className="h-4 w-4 bg-active_hover bg-cover bg-center rounded cursor-pointer"
              onClick={onUnderstandClick}
            />
          )}
          <span className={`pl-2 text-sm text-left ${understand ? 'text-text_selected' : 'text-text_unselected_secondary'} prevent-select`}>
            I have read, understand and agree to the{' '}
            <span
              className="underline underline-offset-auto cursor-pointer"
              onClick={() => open('https://thelegionai.com/terms-of-use')}
            >
              Terms of Use
            </span>{' '}
            and{' '}
            <span
              className="underline underline-offset-auto cursor-pointer"
              onClick={() => open('https://thelegionai.com/privacy')}
            >
              Privacy Policy.
            </span>
          </span>
        </div>
        <div
          className={`mt-4 w-full h-12 rounded 
          ${email && username
              && checkPasswordLength
              && checkPasswordLowerLetter
              && checkPasswordNumber
              && checkPasswordUpperLetter
              && checkPasswordSpecialLetter
              && understand ? 'bg-button' : 'bg-active_hover'}
            grid items-center cursor-pointer`}
          onClick={onSignUpClick}
        >
          <span className="text-black text-sm font-semibold">Sign Up</span>
        </div>
        <div className="mt-2">
          <span className="text-sm text-text_unselected">
            Already have an account?{' '}
            <span
              className="font-mono underline underline-offset-auto cursor-pointer"
              onClick={onSignInClick}
            >
              Sign In
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
